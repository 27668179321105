/// <reference no-default-lib="true"/>
import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {api} from '../../api';
import {showSnackBar} from '../../store/snackBar/actions';
import {getErrorMessage} from '../../utils/service';
import {AirportModel} from '../../models';
import {orderSectionModules} from 'store/page/actions';

export const useFetchAirport = () => {
    const [page, setPage] = useState<number>(1);
    const [lastPage, setLastPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(30);
    const [data, setData] = useState<AirportModel[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [search, setSearch] = useState<string>('');
    const [orderDir, setOrderDir] = useState<'' | 'asc' | 'desc'>('');
    const [orderBy, setOrderBy] = useState<string>('');

    const dispatch = useDispatch();

    // on change
    const onSearchChange = useCallback(
        (e: string | ChangeEvent<HTMLInputElement>) => {
            setPage(1);
            setSearch(e.target.value);
            setData([]);
        },
        [setPage],
    );

    // on change
    const onPageChange = useCallback((_page: number) => {
        setPage(_page);
    }, []);

    // on change
    const onPerPageChange = useCallback(
        (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: unknown;
            }>,
        ) => {
            setPerPage(parseInt(event.target.value));
            setPage(1);
        },
        [setPerPage, setPage],
    );

    const onOrderChange = useCallback(
        (field: string) => () => {
            const order_dir =
                orderBy == field
                    ? orderDir === 'asc'
                        ? 'desc'
                        : orderDir === 'desc'
                        ? ''
                        : 'asc'
                    : 'asc';
            setOrderDir(order_dir);
            setOrderBy(order_dir == '' ? '' : field);
        },
        [orderBy, orderDir],
    );

    const fetch = useCallback(async () => {
        try {
            setLoader(true);
            const _filter: {
                code: string;
                page: number;
                perPage: number;
                pagination: number;
                orderBy?: string;
                direction?: 'asc' | 'desc';
            } = {
                code: search,
                page,
                perPage,
                pagination: 1,
                orderBy: 'code',
            };

            if (orderDir !== '') _filter.direction = orderDir;
            if (orderBy !== '') _filter.orderBy = orderBy;
            const response = await api.profile.fetchAirport(_filter);

            if (response.current_page <= response.last_page) {
                setData((prev) => [...prev, ...response.data]);
                setHasMore(true);
                setLastPage(response.last_page);
            } else {
                setHasMore(false);
            }

            setLastPage(response.last_page);
            setLoader(false);
        } catch (err) {
            setLoader(false);
            dispatch(showSnackBar(getErrorMessage(err), 'error'));
        }
    }, [page, search]);

    useEffect(() => {
        fetch();
    }, [page, search, perPage, orderBy]);

    const onRefresh = () => {
        setRefresh(!refresh);
    };

    return {
        loader,
        data,
        page,
        lastPage,
        search,
        hasMore,
        onSearchChange,
        onPageChange,
        onPerPageChange,
        perPage,
        onRefresh,
        orderDir,
        orderBy,
        onOrderChange,
    };
};

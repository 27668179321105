import {makeStyles} from '@material-ui/core';
import {COLORS} from 'utils';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginRight: '3%',
        marginLeft: '3%',
        marginTop: 10,
        marginBottom: 10,
    },

    formContainer: {
        paddingTop: 10,
    },
    containerSubmitButton: {
        marginTop: 10,
        marginBottom: 10,
        float: 'right',
        width: 'auto',
        display: 'flex',
    },
    cancelContainer: {
        marginRight: 10,
    },
    input: {
        width: '100%',
        marginTop: 10,
        marginBottom: 10,
        padding: 10,
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
    loaderStyle: {
        display: 'flex',
        justifyContent: 'center',
        padding: 20,
    },
}));

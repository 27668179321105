/// <reference no-default-lib="true"/>
import React, {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {
    ButtonBase,
    Chip,
    CircularProgress,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    useTheme,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {
    Button,
    CustomDialog,
    FormContainer,
    InputsContainer,
    SelectInput,
} from '..';
import {FILE_FORMATS, GALLERY_TYPES} from '../../utils/constants';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {useStyles} from './style';
import {ModuleImage} from '../../models/Page';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

interface MainProps {
    value?: string;
    values?: Array<ModuleImage>;
    error?: string;
    galleryType?: number;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onRemoveImage?: (id: number) => void;
    isMultiple?: boolean;
    loading?: boolean;
    title?: string;
    isGallery?: boolean;
}
export const ImageModule = (props: MainProps) => {
    const {
        value = '',
        onChange,
        error = '',
        isMultiple = false,
        loading = false,
        values = [],
        title = 'common.image',
        onRemoveImage,
        isGallery = false,
        galleryType,
    } = props;
    const classes = useStyles();
    const {t} = useTranslation();
    const theme = useTheme();
    const [deleteImageDialog, setDeleteDialog] = useState(false);
    const [idToDelete, setidToDelete] = useState(0);

    const handleDeletebutton = (id: number) => {
        setidToDelete(id);
        setDeleteDialog(true);
    };

    return (
        <>
            <InputsContainer title={`${t(title)}*`}>
                <div>
                    <label className={classes.containerUploadIcon}>
                        <Tooltip title={t('common.upload_image_tooltip')}>
                            <CloudUploadIcon className={classes.uploadIcon} />
                        </Tooltip>
                        <input
                            disabled={loading}
                            type="file"
                            className={classes.inputFile}
                            onChange={onChange}
                            accept={FILE_FORMATS.map((e) => e.format).join(',')}
                        />
                        <p
                            className={
                                loading
                                    ? classes.uploadImgMsgDisabled
                                    : classes.uploadImgMsg
                            }>
                            {t('common.upload_image_msg')}
                        </p>
                        {loading && <CircularProgress size={20} />}
                    </label>
                </div>
                <p className={classes.uploadImgMsgInfo}>
                    {t('common.cover_image_info')}
                </p>
                {!isMultiple ? (
                    <div className={classes.containerPreviewImageSeo}>
                        <img
                            src={value}
                            className={classes.previewCoverImage}
                        />
                    </div>
                ) : (
                    <Grid
                        container
                        justifyContent="flex-start"
                        className={classes.containerPreviewImagesMulti}>
                        {values.map((image, index) => (
                            <div
                                className={classes.containerPreviewImageSeo}
                                key={`image-${index}`}>
                                <img
                                    src={image.path}
                                    className={`${classes.previewCoverImage} ${classes.containerPreviewImageMulti}`}
                                />
                                <ButtonBase
                                    onClick={() => handleDeletebutton(image.id)}
                                    className={classes.buttonDelete}>
                                    <Tooltip
                                        title={t(
                                            'common.cancel_image_tooltip',
                                        )}>
                                        <CancelRoundedIcon
                                            className={classes.cancelIcon}
                                            fontSize="medium"
                                        />
                                    </Tooltip>
                                </ButtonBase>
                            </div>
                        ))}
                    </Grid>
                )}
                {error && <p className={classes.errorWysiwyg}>{t(error)}</p>}
            </InputsContainer>
            <CustomDialog
                open={deleteImageDialog}
                handleClose={() => setDeleteDialog(false)}
                title={t('addDestination.add_image_gallery_delete')}>
                <div>
                    <p>{t('addDestination.delete_message')}</p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={false}
                                title={t('common.cancel_button')}
                                onClick={() => setDeleteDialog(false)}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={false}
                                onClick={() => {
                                    setDeleteDialog(false);
                                    onRemoveImage(idToDelete);
                                }}
                                loader={false}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </div>
            </CustomDialog>
        </>
    );
};

export default ImageModule;

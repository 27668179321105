/// <reference no-default-lib="true"/>
import {CorporateSettingBloc, InputsContainer, Seo} from '../../components';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    addSliderCorporateSettings,
    deleteSliderCorporateSettings,
    fetchCorporateSettings,
} from '../../store/corporateSetting/actions';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import {RootState} from '../../store/rootReducer';
import {
    AppBar,
    Box,
    ButtonBase,
    CircularProgress,
    Tab,
    Tabs,
    Tooltip,
} from '@material-ui/core';
import {useStyles} from './style';
import {useTranslation} from 'react-i18next';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
    ACCESS,
    checkValidations,
    CORPORATE_TYPES_SEO,
    FILE_FORMATS,
    FILE_MAX_SIZE,
    getAccessSection,
    SECTIONS,
    VALIDATION,
} from '../../utils';
import {showSnackBar} from '../../store/snackBar/actions';
import {Slider} from '../../models';
import {useFetchSeoById, useUpdateSeo} from 'hooks';
import UpdateSeo from 'components/UpdateSeo';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

export const CorporateSettings = () => {
    const dispatch = useDispatch();
    const corporateSettings = useSelector(
        (state: RootState) => state.corporateSetting.corporateSettings,
    );
    const sliders = useSelector(
        (state: RootState) => state.corporateSetting.slider,
    );
    const [images, setImages] = useState<Slider[]>([]);
    const [value, setValue] = React.useState(0);
    const [loaderAddSlider, setLoaderAddSlider] = React.useState(false);
    const [tabArray, setTabArray] = React.useState([]);
    const handleChangeTab = (event: React.ChangeEvent, newValue: number) => {
        setValue(newValue);
    };
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(fetchCorporateSettings());
    }, [dispatch]);

    function a11yProps(index: number) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    //handle image data change
    const onChangeImage = useCallback(
        () => async (e) => {
            if (e.target != undefined) {
                if (!e.target.files) return;
                //image validation : max size validation
                const customError = checkValidations(
                    'imageUrl',
                    e.target.files[0].size || '',
                    [VALIDATION.MAX],
                    FILE_MAX_SIZE,
                );
                if (customError === 'validation.imageUrl.max') {
                    dispatch(showSnackBar(t(customError), 'error'));
                    e.target.value = '';
                } else {
                    setLoaderAddSlider(true);
                    await dispatch(
                        addSliderCorporateSettings(e.target.files[0]),
                    );
                    await dispatch(fetchCorporateSettings());
                    setLoaderAddSlider(false);
                }
            }
        },
        [dispatch, t],
    );

    //handle remove image
    const onRemoveImage = useMemo(
        () => (id: number) => async () => {
            const newImages = [...images];
            images.map((element, index) => {
                if (element.id == id) {
                    newImages.splice(index, 1);
                    setImages(newImages);
                }
            });
            setLoaderAddSlider(true);
            await dispatch(deleteSliderCorporateSettings(id));
            await dispatch(fetchCorporateSettings());
            setLoaderAddSlider(false);
        },
        [dispatch, images],
    );

    useEffect(() => {
        const tab = [];
        corporateSettings.map((elemet, index) => {
            const tab1 = {
                id: index,
                name: elemet.titleFr,
                key: index,
            };
            tab.push(tab1);
        });
        setTabArray(tab);
    }, [corporateSettings]);

    return (
        <div className={`${classes.container} global-container`}>
            <p className={classes.infoMsg}>
                {t('common.corporate_slider_info')}
            </p>
            <InputsContainer title={t('settings.slider_title')}>
                {(getAccessSection(SECTIONS.CORPORATE_SETTINGS_SECTION.id) ==
                    ACCESS.ALL ||
                    getAccessSection(SECTIONS.CORPORATE_SETTINGS_SECTION.id) ==
                        ACCESS.ACCESS_UPDATE) && (
                    <>
                        <div className={classes.containerTitle}>
                            <label className={classes.containerUploadIcon}>
                                <Tooltip
                                    title={t(
                                        'addDestination.upload_image_tooltip',
                                    )}>
                                    <CloudUploadIcon
                                        className={classes.uploadIcon}
                                    />
                                </Tooltip>
                                <input
                                    type="file"
                                    className={classes.inputFile}
                                    onChange={onChangeImage()}
                                    accept={FILE_FORMATS.map(
                                        (e) => e.format,
                                    ).join(',')}
                                    disabled={false}
                                />
                                <p className={classes.uploadImgMsg}>
                                    {t('addDestination.upload_image_msg')}
                                </p>
                                {loaderAddSlider && (
                                    <CircularProgress size={20} />
                                )}
                            </label>
                        </div>
                        <p className={classes.uploadImgMsgInfo}>
                            {t('addDestination.gallery_info')}
                        </p>
                    </>
                )}

                {sliders.length > 0 &&
                    sliders.map((item, index) => {
                        return (
                            <div className={classes.containerImage} key={index}>
                                <div className={classes.imageElements}>
                                    <img
                                        src={item.image}
                                        className={classes.image}
                                    />
                                    {(getAccessSection(
                                        SECTIONS.CORPORATE_SETTINGS_SECTION.id,
                                    ) == ACCESS.ALL ||
                                        getAccessSection(
                                            SECTIONS.CORPORATE_SETTINGS_SECTION
                                                .id,
                                        ) == ACCESS.ACCESS_DELETE) && (
                                        <ButtonBase
                                            onClick={onRemoveImage(item.id)}>
                                            <Tooltip
                                                title={t(
                                                    'common.cancel_image_tooltip',
                                                )}>
                                                <CancelRoundedIcon
                                                    className={
                                                        classes.cancelIcon
                                                    }
                                                    fontSize="medium"
                                                />
                                            </Tooltip>
                                        </ButtonBase>
                                    )}
                                </div>
                            </div>
                        );
                    })}
            </InputsContainer>
            <p className={classes.infoMsg}>
                {t('common.corporate_section_info')}
            </p>
            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChangeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        aria-label="scrollable auto tabs example">
                        {tabArray.map((element, index) => {
                            return (
                                <Tab
                                    label={
                                        <strong className={classes.labelText}>
                                            {element.name}
                                        </strong>
                                    }
                                    key={`Tab-${index + 1}`}
                                    {...a11yProps(index)}
                                />
                            );
                        })}
                    </Tabs>
                </AppBar>
                {tabArray.map((element, index) => {
                    return (
                        <TabPanel
                            value={value}
                            index={index}
                            key={`TabPanel-${index + 1}`}>
                            <CorporateSettingBloc
                                id={index}
                                element={corporateSettings[index]}
                            />
                        </TabPanel>
                    );
                })}
            </div>
            <p className={classes.infoMsg}>{t('common.corporate_seo_info')}</p>
            <UpdateSeo
                typesList={CORPORATE_TYPES_SEO}
                sectionId={SECTIONS.CORPORATE_SETTINGS_SECTION.id}
            />
        </div>
    );
};

export default CorporateSettings;

/// <reference no-default-lib="true"/>
import {useUpdateSeo, useFetchSeoById} from '../../hooks';
import React, {useEffect, useState} from 'react';
import {useStyles} from './style';
import {AppBar, Box, Tab, Tabs} from '@material-ui/core';
import {Seo} from '../../components';
import {ACCESS, LANGUAGE, SECTIONS} from '../../utils';
import {getAccessSection} from '../../utils/helpers';

interface TypesInterface {
    id: number;
    name: string;
    key: string;
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

interface MainProps {
    typesList: Array<TypesInterface>;
    sectionId: number;
}
export const UpdateSeo = ({typesList, sectionId}: MainProps) => {
    // style classes
    const classes = useStyles();
    // get details request form hooks
    const {
        loader: loaderFetchSeo,
        data: dataFetchSeo,
        fetch: fetchSeoById,
    } = useFetchSeoById();

    // useUpdateService hooks
    const {
        data,
        error,
        loader,
        onChange,
        onSubmit,
        initData,
        onChangeSeoAndCoverImage,
        onChangeTab,
    } = useUpdateSeo();

    const [firstTime, setFirstTime] = useState(true);

    useEffect(() => {
        if (firstTime) {
            fetchSeoById(typesList[0]?.id);
            setFirstTime(false);
        }
        // eslint-disable-next-line
    }, [setFirstTime]);

    useEffect(
        () => {
            if (dataFetchSeo != undefined) {
                const newData = {
                    seoType: null,
                    image: null,
                    imageUrl: '',
                    coverImage: null,
                    coverImageUrl: '',
                    titleFr: '',
                    titleEn: '',
                    titleDe: '',
                    descriptionFr: '',
                    descriptionEn: '',
                    descriptionDe: '',
                };

                newData.imageUrl = dataFetchSeo.image;
                newData.coverImageUrl = dataFetchSeo.cover_image;

                dataFetchSeo.translations?.map((e) => {
                    if (e.language_id === LANGUAGE.FRENCH) {
                        newData.titleFr = e.title != null ? e.title : '';
                        newData.descriptionFr =
                            e.description != null ? e.description : '';
                    }
                    if (e.language_id === LANGUAGE.ENGLISH) {
                        newData.titleEn = e.title != null ? e.title : '';
                        newData.descriptionEn =
                            e.description != null ? e.description : '';
                    }
                    if (e.language_id === LANGUAGE.DEUTSCH) {
                        newData.titleDe = e.title != null ? e.title : '';
                        newData.descriptionDe =
                            e.description != null ? e.description : '';
                    }
                });
                initData(newData);
            }
        },
        // eslint-disable-next-line
        [dataFetchSeo],
    );

    const [value, setValue] = React.useState(0);

    const handleChangeTab = (event: React.ChangeEvent, newValue: number) => {
        setValue(newValue);
        const selectedSection = typesList[newValue];
        fetchSeoById(selectedSection?.id);
        onChangeTab();
    };

    function a11yProps(index: number) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    return (
        <div className={classes.root}>
            {dataFetchSeo && (
                <>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={value}
                            onChange={handleChangeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            aria-label="scrollable auto tabs example">
                            {typesList.map((element, index) => {
                                return (
                                    <Tab
                                        label={
                                            <strong
                                                className={classes.labelText}>
                                                {element.name}
                                            </strong>
                                        }
                                        key={`Tab-${index + 1}`}
                                        {...a11yProps(index)}
                                    />
                                );
                            })}
                        </Tabs>
                    </AppBar>
                    {typesList.map((element, index) => {
                        return (
                            <TabPanel
                                value={value}
                                index={index}
                                key={`TabPanel-${index + 1}`}>
                                <Seo
                                    imageUrl={data.imageUrl}
                                    imageUrlError={error.imageUrl}
                                    titleFr={data.titleFr}
                                    titleFrError={error.titleFr}
                                    titleEn={data.titleEn}
                                    titleEnError={error.titleEn}
                                    titleDe={data.titleDe}
                                    titleDeError={error.titleDe}
                                    descriptionFr={data.descriptionFr}
                                    descriptionFrError={error.descriptionFr}
                                    descriptionEn={data.descriptionEn}
                                    descriptionEnError={error.descriptionEn}
                                    descriptionDe={data.descriptionDe}
                                    descriptionDeError={error.descriptionDe}
                                    onChangeSeoImage={onChangeSeoAndCoverImage(
                                        'seoImage',
                                    )}
                                    onChangeSeo={onChange}
                                    showSubmitButton={true}
                                    loaderSubmitSeo={loader}
                                    onSubmitAddSeo={onSubmit(element.id)}
                                    showCoverImage={true}
                                    onChangeCoverImage={onChangeSeoAndCoverImage(
                                        'coverImage',
                                    )}
                                    coverImageUrl={data.coverImageUrl}
                                    coverImageUrlError={error.coverImageUrl}
                                    globalLoader={loaderFetchSeo}
                                    indexSeoType={element.id}
                                    globalDisabled={
                                        getAccessSection(sectionId) !=
                                            ACCESS.ALL &&
                                        getAccessSection(sectionId) !=
                                            ACCESS.ACCESS_UPDATE
                                    }
                                />
                            </TabPanel>
                        );
                    })}
                </>
            )}
        </div>
    );
};

export default UpdateSeo;

/// <reference no-default-lib="true"/>
import React, {useEffect, useRef, useState} from 'react';
import {useStyles} from './style';
import {CircularProgress, Grid} from '@material-ui/core';
import FormContainer from 'components/FormContainer/FormContainer';
import TextInput from 'components/TextInput/TextInput';
import Button from 'components/Button/Button';
import {useTranslation} from 'react-i18next';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';
import useManageAirport from 'hooks/airport/useManageAirport';
import {
    ACCESS,
    LANGUAGE,
    PATHS,
    SECTIONS,
    convertDMSToDD,
    TUNISIA_LATITUDE,
    TUNISIA_LONGITUDE,
    config,
    getAccessSection,
    getErrorMessage,
    history,
} from 'utils';
import {useFetchAirportCountryList} from 'hooks/airportCountry/useFetchAirportCountryList';
import SelectInput from 'components/SelectInput/SelectInput';
import {AirportById} from 'models';
import {useFetchAirportInfos} from 'hooks/airport/useFetchAirportInfo';
import {api} from 'api';
import {showSnackBar} from 'store/snackBar/actions';
import {useDispatch} from 'react-redux';
import ReactMapGL, {NavigationControl, Marker} from 'react-map-gl';
import CircleMap from 'components/ui/svg/CircleMap';
import MapPin from 'components/ui/svg/MapPin';
import {CallbackEvent} from 'react-map-gl/src/components/draggable-control';
import DraggableMap from 'components/DraggableMap/DraggableMap';

export type AirportProps = {
    isUpdate?: boolean;
    airportDataDetails?: AirportById;
    detailsLoader?: boolean;
};

function AirportForm({
    isUpdate,
    airportDataDetails,
    detailsLoader,
}: AirportProps) {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [coordinatesLoader, setCoordinatesLoader] = useState<boolean>(false);

    const {data: airportCountryData} = useFetchAirportCountryList({
        isAllCountries: true,
    });

    const {
        data: airportData,
        error: airportError,
        loader: airportLoader,
        onChange: onChangeAirport,
        onSubmit: onSubmitAirport,
        resetData: resetAirportData,
        setData: setAirportData,
    } = useManageAirport(
        {
            code: '',
            cityCode: '',
            airportNameFr: '',
            airportNameEn: '',
            airportNameDe: '',
            airportCityNameFr: '',
            airportCityNameEn: '',
            airportCityNameDe: '',
            longitude: TUNISIA_LONGITUDE,
            latitude: TUNISIA_LATITUDE,
            airportCountryId: 0,
        },
        isUpdate,
        airportDataDetails && airportDataDetails.id ? airportDataDetails.id : 0,
    );

    const getTranslationByProperty = (
        translations,
        languageId,
        propertyName,
    ) => {
        const translation = translations.find(
            (value) => value.language_id === languageId,
        );
        return translation ? translation[propertyName] : '';
    };

    useEffect(() => {
        if (airportDataDetails) {
            setAirportData({
                code: airportDataDetails.code,
                cityCode: airportDataDetails.city_code,
                longitude: Number(airportDataDetails.longitude),
                latitude: Number(airportDataDetails.latitude),
                airportCountryId: Number(airportDataDetails.airport_country_id),
                airportNameFr: getTranslationByProperty(
                    airportDataDetails.translations,
                    LANGUAGE.FRENCH,
                    'name',
                ),
                airportNameEn: getTranslationByProperty(
                    airportDataDetails.translations,
                    LANGUAGE.ENGLISH,
                    'name',
                ),
                airportNameDe: getTranslationByProperty(
                    airportDataDetails.translations,
                    LANGUAGE.DEUTSCH,
                    'name',
                ),
                airportCityNameFr: getTranslationByProperty(
                    airportDataDetails.translations,
                    LANGUAGE.FRENCH,
                    'city_name',
                ),
                airportCityNameEn: getTranslationByProperty(
                    airportDataDetails.translations,
                    LANGUAGE.ENGLISH,
                    'city_name',
                ),
                airportCityNameDe: getTranslationByProperty(
                    airportDataDetails.translations,
                    LANGUAGE.DEUTSCH,
                    'city_name',
                ),
            });
        }
    }, [airportDataDetails]);

    const handleCancelButton = () => {
        history.push(PATHS.AIRPORT);
    };

    const getAirportCoordinates = async () => {
        try {
            setCoordinatesLoader(true);

            const res = await api.airports.fetchAirportInfos(airportData.code);

            if (res && res.data.latt !== '' && res.data.long !== '') {
                const {latt, long} = res.data;

                const latitudeDD = convertDMSToDD(latt);
                const longitudeDD = convertDMSToDD(long);

                if (latitudeDD !== null && longitudeDD !== null) {
                    setAirportData({
                        ...airportData,
                        longitude: longitudeDD,
                        latitude: latitudeDD,
                    });

                    dispatch(
                        showSnackBar(
                            t('airport.airport_infos_updated'),
                            'success',
                        ),
                    );
                } else {
                    dispatch(
                        showSnackBar(t('airport.format_invalid'), 'error'),
                    );
                }
            } else {
                dispatch(
                    showSnackBar(t('airport.airport_not_existed'), 'error'),
                );
            }
        } catch (err) {
            dispatch(showSnackBar(t('airport.airport_not_existed'), 'error'));
        } finally {
            setCoordinatesLoader(false);
        }
    };

    const handleChangeAirportCoordinates = (long, lat) => {
        setAirportData({
            ...airportData,
            longitude: long,
            latitude: lat,
        });
    };

    return (
        <>
            {isUpdate && detailsLoader ? (
                <div className={classes.loaderStyle}>
                    <CircularProgress size={30} />
                </div>
            ) : (
                <div className={`${classes.container} global-container`}>
                    <FormContainer title={t('airport.form.titles')}>
                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    flagImg={<IconFlagFR />}
                                    error={t(airportError.airportNameFr)}
                                    placeholder={t(
                                        'airport.form.title_fr_place_holder',
                                    )}
                                    value={airportData.airportNameFr}
                                    onChange={onChangeAirport('airportNameFr')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    flagImg={<IconFlagUK />}
                                    error={t(airportError.airportNameEn)}
                                    placeholder={t(
                                        'airport.form.title_en_place_holder',
                                    )}
                                    value={airportData.airportNameEn}
                                    onChange={onChangeAirport('airportNameEn')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    flagImg={<IconFlagDE />}
                                    error={t(airportError.airportNameDe)}
                                    placeholder={t(
                                        'airport.form.title_de_place_holder',
                                    )}
                                    value={airportData.airportNameDe}
                                    onChange={onChangeAirport('airportNameDe')}
                                />
                            </Grid>
                        </Grid>
                    </FormContainer>

                    <FormContainer title={t('airport.form.city_titles')}>
                        <Grid
                            container
                            justifyContent="space-between"
                            spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    flagImg={<IconFlagFR />}
                                    error={t(airportError.airportCityNameFr)}
                                    placeholder={t(
                                        'airport.form.city_title_fr_place_holder',
                                    )}
                                    value={airportData.airportCityNameFr}
                                    onChange={onChangeAirport(
                                        'airportCityNameFr',
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    flagImg={<IconFlagUK />}
                                    error={t(airportError.airportCityNameEn)}
                                    placeholder={t(
                                        'airport.form.city_title_en_place_holder',
                                    )}
                                    value={airportData.airportCityNameEn}
                                    onChange={onChangeAirport(
                                        'airportCityNameEn',
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextInput
                                    flagImg={<IconFlagDE />}
                                    error={t(airportError.airportCityNameDe)}
                                    placeholder={t(
                                        'airport.form.city_title_de_place_holder',
                                    )}
                                    value={airportData.airportCityNameDe}
                                    onChange={onChangeAirport(
                                        'airportCityNameDe',
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </FormContainer>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} sm={4}>
                            <div
                                className={classes.input}
                                style={{marginTop: 17}}>
                                <SelectInput
                                    none={true}
                                    label={t('airport.form.country')}
                                    error={airportError.airportCountryId}
                                    value={airportData.airportCountryId}
                                    onChange={onChangeAirport(
                                        'airportCountryId',
                                    )}
                                    data={airportCountryData}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className={classes.input}>
                                <TextInput
                                    value={airportData.code}
                                    label={t('airport.form.code_title')}
                                    onChange={onChangeAirport('code')}
                                    error={t(airportError.code)}
                                    placeholder={t(
                                        'airport.form.code_place_holder',
                                    )}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className={classes.input}>
                                <TextInput
                                    value={airportData.cityCode}
                                    label={t('airport.form.city_code_title')}
                                    onChange={onChangeAirport('cityCode')}
                                    error={t(airportError.cityCode)}
                                    placeholder={t(
                                        'airport.form.city_code_place_holder',
                                    )}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} sm={4}>
                            <div className={classes.input}>
                                <TextInput
                                    type="number"
                                    value={airportData.longitude.toString()}
                                    label={t('airport.form.long_title')}
                                    onChange={onChangeAirport(
                                        'longitude',
                                        -180,
                                        180,
                                    )}
                                    error={t(airportError.longitude)}
                                    placeholder={t(
                                        'airport.form.long_place_holder',
                                    )}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className={classes.input}>
                                <TextInput
                                    type="number"
                                    value={airportData.latitude.toString()}
                                    label={t('airport.form.lat_title')}
                                    onChange={onChangeAirport(
                                        'latitude',
                                        -90,
                                        90,
                                    )}
                                    error={t(airportError.latitude)}
                                    placeholder={t(
                                        'airport.form.lat_place_holder',
                                    )}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className={classes.input}>
                                <Button
                                    style={{
                                        marginTop: 8,
                                    }}
                                    disabled={
                                        (getAccessSection(
                                            SECTIONS.MANAGING_AIRPORTS_SECTION
                                                .id,
                                        ) != ACCESS.ALL &&
                                            getAccessSection(
                                                SECTIONS
                                                    .MANAGING_AIRPORTS_SECTION
                                                    .id,
                                            ) != ACCESS.ACCESS_UPDATE) ||
                                        airportData.code == ''
                                    }
                                    loader={coordinatesLoader}
                                    onClick={getAirportCoordinates}
                                    title={t(
                                        'airport.form.long_lat_action_title',
                                    )}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid>
                        <DraggableMap
                            long={Number(airportData.longitude)}
                            lat={Number(airportData.latitude)}
                            handleChangeAirportCoordinates={
                                handleChangeAirportCoordinates
                            }
                        />
                    </Grid>

                    <div className={classes.containerSubmitButton}>
                        <div className={classes.cancelContainer}>
                            <Button
                                disabled={false}
                                onClick={handleCancelButton}
                                title={t('common.cancel_button')}
                            />
                        </div>
                        <Button
                            disabled={
                                getAccessSection(
                                    SECTIONS.MANAGING_AIRPORTS_SECTION.id,
                                ) != ACCESS.ALL &&
                                getAccessSection(
                                    SECTIONS.MANAGING_AIRPORTS_SECTION.id,
                                ) != ACCESS.ACCESS_UPDATE
                            }
                            onClick={() => onSubmitAirport()}
                            loader={airportLoader}
                            title={t('common.save_button')}
                        />
                    </div>
                </div>
            )}
        </>
    );
}

export default AirportForm;

/// <reference no-default-lib="true"/>
import {
    useUpdateFaq,
    useFetchFaqById,
    useRadioTreeSelect,
    useFetchFaqCategoriesTree,
    useMultipleSelect,
} from '../../hooks';
import React, {useEffect} from 'react';
import {useStyles} from './style';
import {useTranslation} from 'react-i18next';
import {
    InputsContainer,
    CustomTabs,
    TextInput,
    Button,
    RadioSelectTree,
    TinyMceSimpleWysiwyg,
    MultipleSelect,
} from '..';
import {useRouteMatch} from 'react-router-dom';
import {history} from '../../utils/history';
import {PATHS} from '../../utils/paths';
import {CircularProgress, Grid} from '@material-ui/core';
import {ACCESS, LANGUAGE, SECTIONS} from '../../utils';
import {getAccessSection, specialArrayEquals} from '../../utils/helpers';
import {useFetchCollapseTypes} from 'hooks/settings/useFetchCollapseTypes';

interface MainProps {
    isInternal: boolean;
}
export const UpdateFaqComponent = ({isInternal}: MainProps) => {
    // style classes
    const classes = useStyles();
    // translation hooks
    const {t} = useTranslation();

    // get params from url
    const match = useRouteMatch<{id: string}>();

    const {
        loader: loaderCategories, // fetch data loader
        data: dataCategories, // table data
    } = useFetchFaqCategoriesTree(isInternal);

    const {data: collapseTypesList, loader: loaderTypes} =
        useFetchCollapseTypes();

    // get details request form hooks
    const {loader: loaderFetchFaq, data: dataFetchFaq} = useFetchFaqById(
        match.params.id,
    );

    // useUpdateFaq hooks
    const {
        data: dataUpdateFaq,
        error: errorUpdateFaq,
        loader: loaderUpdateFaq,
        onChange: onChangeUpdateFaq,
        onSubmit: onSubmitUpdateFaq,
        updateAllData: setDataFaq,
        setNewCategory: setNewCategoryUpdateFaq,
        onEditorFrStateChange,
        onEditorEnStateChange,
        onEditorDeStateChange,
    } = useUpdateFaq(
        {
            categoryId: 0,
            questionFr: '',
            questionEn: '',
            questionDe: '',
            responseFr: '',
            responseEn: '',
            responseDe: '',
        },
        isInternal,
    );

    // handle click cancel button
    const handleCancelButton = () => {
        isInternal ? history.push(PATHS.FAQ_INTERNAL) : history.push(PATHS.FAQ);
    };

    //set loaded data
    useEffect(() => {
        if (dataFetchFaq != undefined) {
            const newData = dataUpdateFaq;

            newData.categoryId = dataFetchFaq.collapse_category_id;

            dataFetchFaq.translations.map((item) => {
                if (item.language_id === LANGUAGE.FRENCH) {
                    newData.questionFr = item.question;
                    newData.responseFr = item.response;
                }
                if (item.language_id === LANGUAGE.ENGLISH) {
                    newData.questionEn = item.question;
                    newData.responseEn = item.response;
                }
                if (item.language_id === LANGUAGE.DEUTSCH) {
                    newData.questionDe = item.question;
                    newData.responseDe = item.response;
                }
            });

            setDefaultCtegory(dataFetchFaq.collapse_category_id);
            setDataFaq(newData);
        }
        // eslint-disable-next-line
    }, [dataFetchFaq]);

    const {
        handleChange: handleCategoryChange,
        selectedItem: selectedCategory,
        setDefaultItem: setDefaultCtegory,
    } = useRadioTreeSelect();

    useEffect(
        () => {
            setNewCategoryUpdateFaq(selectedCategory);
        }, // eslint-disable-next-line
        [selectedCategory],
    );

    return (
        <div className={`${classes.container} global-container`}>
            {loaderFetchFaq && (
                <CircularProgress size={30} className={classes.loader} />
            )}

            {!loaderFetchFaq && (
                <div>
                    <Grid
                        item={true}
                        container
                        className={classes.faqCategories}
                        spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <InputsContainer
                                title={t('addFaq.categories_place_holder')}>
                                <div className={classes.categoryContainer}>
                                    <RadioSelectTree
                                        loader={loaderCategories}
                                        selectedItem={selectedCategory}
                                        handleChange={(id) => {
                                            handleCategoryChange(id);
                                        }}
                                        data={dataCategories.map((e) => ({
                                            id: e.id,
                                            value: e.name,
                                            children: e.child_categories.map(
                                                (child) => ({
                                                    id: child.id,
                                                    value: child.name,
                                                }),
                                            ),
                                        }))}
                                    />
                                </div>
                                {errorUpdateFaq.categoryId !== '' && (
                                    <p className={classes.errorWysiwyg}>
                                        {t(errorUpdateFaq.categoryId)}
                                    </p>
                                )}
                            </InputsContainer>
                        </Grid>
                    </Grid>
                    <InputsContainer title={t('addFaq.faq_question_title')}>
                        <CustomTabs
                            childrenFR={
                                <div>
                                    <div className={classes.questionContiner}>
                                        <TextInput
                                            value={dataUpdateFaq.questionFr}
                                            label={t('')}
                                            onChange={onChangeUpdateFaq(
                                                'questionFr',
                                            )}
                                            error={''}
                                            variant="normal"
                                            multiple
                                            placeholder={t(
                                                'addFaq.faq_question_fr',
                                            )}
                                            heightMutliple={100}
                                        />
                                    </div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={
                                            onEditorFrStateChange
                                        }
                                        content={dataUpdateFaq.responseFr}
                                        placeholder={t(
                                            'addFaq.faq_response_fr',
                                        )}
                                        toolBarWithLink={true}
                                    />
                                </div>
                            }
                            childrenEN={
                                <div>
                                    <div className={classes.questionContiner}>
                                        <TextInput
                                            value={dataUpdateFaq.questionEn}
                                            label={t('')}
                                            onChange={onChangeUpdateFaq(
                                                'questionEn',
                                            )}
                                            error={''}
                                            variant="normal"
                                            multiple
                                            placeholder={t(
                                                'addFaq.faq_question_en',
                                            )}
                                            heightMutliple={100}
                                        />
                                    </div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={
                                            onEditorEnStateChange
                                        }
                                        content={dataUpdateFaq.responseEn}
                                        placeholder={t(
                                            'addFaq.faq_response_en',
                                        )}
                                        toolBarWithLink={true}
                                    />
                                </div>
                            }
                            childrenDE={
                                <div>
                                    <div className={classes.questionContiner}>
                                        <TextInput
                                            value={dataUpdateFaq.questionDe}
                                            label={t('')}
                                            onChange={onChangeUpdateFaq(
                                                'questionDe',
                                            )}
                                            error={''}
                                            variant="normal"
                                            multiple
                                            placeholder={t(
                                                'addFaq.faq_question_de',
                                            )}
                                            heightMutliple={100}
                                        />
                                    </div>
                                    <TinyMceSimpleWysiwyg
                                        onEditorStateChange={
                                            onEditorDeStateChange
                                        }
                                        content={dataUpdateFaq.responseDe}
                                        placeholder={t(
                                            'addFaq.faq_response_de',
                                        )}
                                        toolBarWithLink={true}
                                    />
                                </div>
                            }
                        />
                        {errorUpdateFaq.questionFr !== '' && (
                            <p className={classes.errorWysiwyg}>
                                {t(errorUpdateFaq.questionFr)}
                            </p>
                        )}
                        {errorUpdateFaq.responseFr !== '' && (
                            <p className={classes.errorWysiwyg}>
                                {t(errorUpdateFaq.responseFr)}
                            </p>
                        )}
                        {errorUpdateFaq.questionEn !== '' && (
                            <p className={classes.errorWysiwyg}>
                                {t(errorUpdateFaq.questionEn)}
                            </p>
                        )}
                        {errorUpdateFaq.responseEn !== '' && (
                            <p className={classes.errorWysiwyg}>
                                {t(errorUpdateFaq.responseEn)}
                            </p>
                        )}
                        {errorUpdateFaq.questionDe !== '' && (
                            <p className={classes.errorWysiwyg}>
                                {t(errorUpdateFaq.questionDe)}
                            </p>
                        )}
                        {errorUpdateFaq.responseDe !== '' && (
                            <p className={classes.errorWysiwyg}>
                                {t(errorUpdateFaq.responseDe)}
                            </p>
                        )}
                    </InputsContainer>
                    <div className={classes.containerSubmitButton}>
                        <div className={classes.cancelContainer}>
                            <Button
                                disabled={false}
                                onClick={handleCancelButton}
                                title={t('common.cancel_button')}
                            />
                        </div>
                        <Button
                            disabled={
                                loaderUpdateFaq ||
                                (isInternal
                                    ? getAccessSection(
                                          SECTIONS
                                              .MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                              .id,
                                      ) != ACCESS.ALL &&
                                      getAccessSection(
                                          SECTIONS
                                              .MANAGING_COLLAPSE_INTERNAL_PAGE_SECTION
                                              .id,
                                      ) != ACCESS.ACCESS_UPDATE
                                    : getAccessSection(
                                          SECTIONS.FAQ_SECTION.id,
                                      ) != ACCESS.ALL &&
                                      getAccessSection(
                                          SECTIONS.FAQ_SECTION.id,
                                      ) != ACCESS.ACCESS_UPDATE)
                            }
                            type="submit"
                            onClick={onSubmitUpdateFaq(match.params.id)}
                            loader={loaderUpdateFaq}
                            title={t('common.save_button')}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default UpdateFaqComponent;

/// <reference no-default-lib="true"/>
import React, {useEffect, useMemo, useState} from 'react';
import {Grid, IconButton, Tooltip} from '@material-ui/core';
import {
    AddButton,
    Button,
    CustomDialog,
    FormContainer,
    InputsContainer,
    Media,
    MediaForm,
    Table,
    TextInput,
} from '..';
import {useTranslation} from 'react-i18next';
import {Delete, Edit} from '@material-ui/icons';
import {ModuleConfiguration, ModuleMedia} from '../../models/Page';
import {getFrValue} from '../../utils/helpers';
import {useStyles} from './style';
import {OPEN_MODE} from 'utils';
import {IconFlagFR, IconFlagDE, IconFlagUK} from 'material-ui-flags';

interface MainProps {
    gallery: Array<ModuleMedia>;
    onChange: (data: Array<ModuleMedia>) => void;
    error?: string;
    configuration?: ModuleConfiguration;
    onChangeConfiguration?: (
        config: ModuleConfiguration,
        field: string,
    ) => void;
}
export const MagazineGalleryForm = (props: MainProps) => {
    const {gallery, onChange, error, configuration, onChangeConfiguration} =
        props;
    const {t} = useTranslation();
    const classes = useStyles();
    const [deleteImageGalleryDialog, setDeleteImageGalleryDialog] =
        useState(false);
    const [addImageGalleryDialog, setAddImageGalleryDialog] = useState(false);
    const [imageToUpdate, setImageToUpdate] = useState<ModuleMedia>();
    const [indexUpdate, setIndexUpdate] = useState(0);
    const [maxPriority, setMaxPriority] = useState(1);
    const [updateImageGalleryDialog, setUpdateImageGalleryDialog] =
        useState(false);
    const [imageGalleryToDelete, setImageGalleryToDelete] = useState(0);

    const openDeleteMediaDialog = (id: number) => {
        setDeleteImageGalleryDialog(true);
        setImageGalleryToDelete(id);
    };

    const openDialogUpdateGallery = (image: ModuleMedia, index: number) => {
        setMaxPriority(gallery.length);
        setImageToUpdate(image);
        setIndexUpdate(index);
        setUpdateImageGalleryDialog(true);
    };
    const openDialogAddGallery = () => {
        setMaxPriority(gallery.length);
        setAddImageGalleryDialog(true);
    };

    const deleteMedia = () => {
        const index = gallery.findIndex(
            (media) => media.id == imageGalleryToDelete,
        );
        const deletedItem = gallery.filter((e) => e.id == imageGalleryToDelete);

        if (index !== -1) {
            const newMedia = [...gallery];
            newMedia.splice(index, 1);

            const notModifiedItems = gallery.filter(
                (e) => e.pivot.priority < deletedItem[0].pivot.priority,
            );
            const tab = gallery
                .filter((e) => e.pivot.priority > deletedItem[0].pivot.priority)
                .map((element) => {
                    const updatedElement = {
                        ...element,
                        pivot: {
                            ...element.pivot,
                            priority: element.pivot.priority - 1,
                        },
                    };
                    return updatedElement;
                });

            onChange([...notModifiedItems, ...tab]);
        }
        setDeleteImageGalleryDialog(false);
    };

    const handelUpdateMedia = (data: ModuleMedia) => {
        if (data.pivot.url == null || data.pivot.url == '') {
            data.pivot.open_mode = 1;
        }
        const newData = [...gallery];
        newData[indexUpdate] = data;

        const tab = newData.map((element) => {
            if (
                element.pivot.priority == data.pivot.priority &&
                element.path != data.path
            ) {
                const updatedElement = {
                    ...element,
                    pivot: {
                        ...element.pivot,
                        priority: imageToUpdate.pivot.priority,
                    },
                };
                return updatedElement;
            }
            if (
                element.pivot.priority == data.pivot.priority ||
                element.path == data.path
            ) {
                const updatedElement = {
                    ...element,
                    pivot: {
                        ...element.pivot,
                        priority: data.pivot.priority,
                    },
                };
                return updatedElement;
            }
            return element;
        });

        onChange(tab);
        setUpdateImageGalleryDialog(false);
    };
    const handelAddMedia = (data: ModuleMedia) => {
        setAddImageGalleryDialog(false);

        if (data.pivot.url == null || data.pivot.url == '') {
            data.pivot.open_mode = 1;
        }
        const newData = gallery;
        let samePriorty = [];
        samePriorty = newData.filter(
            (e) => e.pivot.priority == data.pivot.priority,
        );
        if (samePriorty?.length > 0) {
            const PriorityNotModified = newData?.filter(
                (e) => e.pivot.priority < data.pivot.priority,
            );
            const priorityUp = newData
                ?.filter((e) => e.pivot.priority >= data.pivot.priority)
                .map((element) => {
                    const updatedElement = {
                        ...element,
                        pivot: {
                            ...element.pivot,
                            priority: element.pivot.priority + 1,
                        },
                    };
                    return updatedElement;
                });

            const result = [...PriorityNotModified, ...priorityUp, data];
            onChange([...result]);
        } else {
            onChange([...gallery, data]);
        }
    };

    useEffect(() => {
        onChangeConfiguration(
            {
                ...configuration,
                titleFr: configuration?.titleFr,
                titleEn: configuration?.titleEn,
                titleDe: configuration?.titleDe,
            },
            '',
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeConfig = useMemo(
        () => (field: string) => (e) => {
            if (e != undefined) {
                if (field == 'titleFr') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            titleFr: e.target.value,
                        },
                        field,
                    );
                }
                if (field == 'titleEn') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            titleEn: e.target.value,
                        },
                        field,
                    );
                }
                if (field == 'titleDe') {
                    onChangeConfiguration(
                        {
                            ...configuration,
                            titleDe: e.target.value,
                        },
                        field,
                    );
                }
            }
        },
        [configuration, onChangeConfiguration],
    );

    return (
        <>
            <FormContainer title={t('addDestination.titles')}>
                <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            flagImg={<IconFlagFR />}
                            placeholder={t(
                                'addDestination.add_image_gallery_title_fr',
                            )}
                            value={configuration?.titleFr}
                            onChange={onChangeConfig('titleFr')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            flagImg={<IconFlagUK />}
                            placeholder={t(
                                'addDestination.add_image_gallery_title_en',
                            )}
                            value={configuration.titleEn}
                            onChange={onChangeConfig('titleEn')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextInput
                            flagImg={<IconFlagDE />}
                            placeholder={t(
                                'addDestination.add_image_gallery_title_de',
                            )}
                            value={configuration.titleDe}
                            onChange={onChangeConfig('titleDe')}
                        />
                    </Grid>
                </Grid>
            </FormContainer>
            <FormContainer title={t('updatePage.gallery_magazine_label')}>
                {error && <p className={classes.error}>{t(error)}</p>}
                <Table
                    loader={false}
                    headerData={[
                        {
                            key: 'image',
                            title: t('addDestination.imgGallery'),
                        },
                        {
                            key: 'title',
                            title: t('addDestination.title_item'),
                        },
                        {
                            key: 'url',
                            title: t('addDestination.urlGallery'),
                        },
                        {
                            key: 'priority',
                            title: t('addDestination.priorityGallery'),
                        },

                        {key: 'action', title: ''},
                    ]}
                    bodyData={gallery?.map(
                        (image: ModuleMedia, index: number) => {
                            return {
                                image: (
                                    <Media
                                        media={{
                                            path: image.path,
                                            extension: image.extension,
                                            id: image.id,
                                        }}
                                    />
                                ),
                                title: <p>{image.translations[0]?.title}</p>,
                                url: <p>{image.pivot?.url}</p>,
                                priority: <p>{image.pivot?.priority}</p>,
                                action: (
                                    <div style={{display: 'flex'}}>
                                        <div>
                                            <Tooltip title={t('common.edit')}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={() =>
                                                        openDialogUpdateGallery(
                                                            image,
                                                            index,
                                                        )
                                                    }>
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div>
                                            <Tooltip title={t('common.delete')}>
                                                <IconButton
                                                    color="primary"
                                                    component="span"
                                                    onClick={() =>
                                                        openDeleteMediaDialog(
                                                            image.id,
                                                        )
                                                    }>
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                ),
                            };
                        },
                    )}
                />
                <Grid
                    justifyContent="center"
                    container
                    className={classes.addButtonContainer}>
                    <AddButton
                        onAddClick={openDialogAddGallery}
                        title={t('common.add')}
                    />
                </Grid>
            </FormContainer>
            <CustomDialog
                open={updateImageGalleryDialog}
                handleClose={() => setUpdateImageGalleryDialog(false)}
                title={t('addPage.update_magazine_gallery_title')}
                withScroll={true}>
                <MediaForm
                    handelAddMedia={handelUpdateMedia}
                    closeDialog={() => setUpdateImageGalleryDialog(false)}
                    update={true}
                    image={imageToUpdate}
                    isMagazineGallery={true}
                    maxPriority={maxPriority}
                />
            </CustomDialog>
            <CustomDialog
                open={addImageGalleryDialog}
                handleClose={() => setAddImageGalleryDialog(false)}
                title={t('addPage.add_magazine_gallery_title')}>
                <MediaForm
                    isMagazineGallery={true}
                    handelAddMedia={handelAddMedia}
                    closeDialog={() => setAddImageGalleryDialog(false)}
                    maxPriority={maxPriority + 1}
                />
            </CustomDialog>
            <CustomDialog
                open={deleteImageGalleryDialog}
                handleClose={() => setDeleteImageGalleryDialog(false)}
                title={t('addDestination.add_image_gallery_delete')}>
                <div>
                    <p>{t('addDestination.delete_message')}</p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={false}
                                title={t('common.cancel_button')}
                                onClick={() =>
                                    setDeleteImageGalleryDialog(false)
                                }
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={false}
                                onClick={() => deleteMedia()}
                                loader={false}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </div>
            </CustomDialog>
        </>
    );
};

export default MagazineGalleryForm;

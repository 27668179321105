/// <reference no-default-lib="true"/>
import {useUpdateSeo, useFetchSeoById} from '../../hooks';
import React, {useEffect, useState} from 'react';
import {AppBar, Box, Tab, Tabs} from '@material-ui/core';
import {Seo} from '../../components';
import {ACCESS, LANGUAGE, SECTIONS, TYPES_SEO} from '../../utils';
import {getAccessSection} from '../../utils/helpers';
import UpdateSeo from 'components/UpdateSeo';
import {useStyles} from '../../components/UpdateSeo/style';

export const UpdateSiteSeo = () => {
    const classes = useStyles();
    return (
        <div className={`${classes.container} global-container`}>
            <UpdateSeo
                typesList={TYPES_SEO}
                sectionId={SECTIONS.SETTINGS_SECTION.id}
            />
        </div>
    );
};

export default UpdateSiteSeo;

import {Button, TextInput} from 'components';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useStyles} from './style';
import useManageAirportCountry from 'hooks/airportCountry/useManageAirportCountry';
import {useFetchAirportCountryById} from 'hooks/airportCountry/useFetchAirportCountryById';
import {ACCESS, LANGUAGE, SECTIONS, getAccessSection} from 'utils';
import {CircularProgress} from '@material-ui/core';

export type AirportCountryProps = {
    closeDialog: () => void;
    isUpdate?: boolean;
    airportCountryId: number;
    onSuccessManageAirportCountry: () => void;
};
function AirportCountriesForm({
    closeDialog,
    isUpdate,
    airportCountryId,
    onSuccessManageAirportCountry,
}: AirportCountryProps) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {data: dataDetails, loader: detailsLoader} =
        useFetchAirportCountryById(airportCountryId);
    const airportCountryDataDetails =
        airportCountryId !== 0 ? dataDetails : null;

    const {
        data: airportCountryData,
        error: airportCountryError,
        loader: airportCountryLoader,
        onChange: onChangeAirportCountry,
        onSubmit: onSubmitAirportCountry,
        resetData: resetAirportData,
        setData: setAirportCountryData,
    } = useManageAirportCountry(
        {
            code: '',
            airportCountryNameFr: '',
            airportCountryNameEn: '',
            airportCountryNameDe: '',
        },
        isUpdate,
        airportCountryDataDetails ? airportCountryDataDetails?.id : 0,
    );

    const getTranslationByName = (translations, languageId) => {
        const translation = translations.find(
            (value) => value.language_id === languageId,
        );
        return translation?.name ?? '';
    };

    useEffect(() => {
        if (airportCountryDataDetails) {
            setAirportCountryData({
                code: airportCountryDataDetails.code,
                airportCountryNameFr: getTranslationByName(
                    airportCountryDataDetails.translations,
                    LANGUAGE.FRENCH,
                ),
                airportCountryNameEn: getTranslationByName(
                    airportCountryDataDetails.translations,
                    LANGUAGE.ENGLISH,
                ),
                airportCountryNameDe: getTranslationByName(
                    airportCountryDataDetails.translations,
                    LANGUAGE.DEUTSCH,
                ),
            });
        }
    }, [airportCountryDataDetails]);
    return (
        <>
            {detailsLoader ? (
                <div className={classes.loaderStyle}>
                    <CircularProgress size={30} />
                </div>
            ) : (
                <form
                    onSubmit={onSubmitAirportCountry(
                        onSuccessManageAirportCountry,
                    )}>
                    <div className={classes.input}>
                        <TextInput
                            value={airportCountryData.airportCountryNameFr}
                            label={t('airport.form.title_fr')}
                            onChange={onChangeAirportCountry(
                                'airportCountryNameFr',
                            )}
                            error={t(airportCountryError.airportCountryNameFr)}
                            placeholder={t(
                                'airport.form.title_fr_place_holder',
                            )}
                        />
                    </div>
                    <div className={classes.input}>
                        <TextInput
                            value={airportCountryData.airportCountryNameEn}
                            label={t('airport.form.title_en')}
                            onChange={onChangeAirportCountry(
                                'airportCountryNameEn',
                            )}
                            error={t(airportCountryError.airportCountryNameEn)}
                            placeholder={t(
                                'airport.form.title_en_place_holder',
                            )}
                        />
                    </div>
                    <div className={classes.input}>
                        <TextInput
                            value={airportCountryData.airportCountryNameDe}
                            label={t('airport.form.title_de')}
                            onChange={onChangeAirportCountry(
                                'airportCountryNameDe',
                            )}
                            error={t(airportCountryError.airportCountryNameDe)}
                            placeholder={t(
                                'airport.form.title_de_place_holder',
                            )}
                        />
                    </div>

                    <div className={classes.input}>
                        <TextInput
                            value={airportCountryData.code}
                            label={t('airport.form.code_title')}
                            onChange={onChangeAirportCountry('code')}
                            error={t(airportCountryError.code)}
                            placeholder={t('airport.form.code_place_holder')}
                        />
                    </div>

                    <div className={classes.send}>
                        <div className={classes.cancelContainer}>
                            <Button
                                disabled={airportCountryLoader}
                                onClick={closeDialog}
                                title={t('common.cancel_button')}
                            />
                        </div>
                        <Button
                            disabled={
                                airportCountryLoader ||
                                (getAccessSection(
                                    SECTIONS.MANAGING_AIRPORTS_SECTION.id,
                                ) != ACCESS.ALL &&
                                    getAccessSection(
                                        SECTIONS.MANAGING_AIRPORTS_SECTION.id,
                                    ) != ACCESS.ACCESS_UPDATE)
                            }
                            type="submit"
                            loader={airportCountryLoader}
                            title={t('common.save_button')}
                        />
                    </div>
                </form>
            )}
        </>
    );
}

export default AirportCountriesForm;

/// <reference no-default-lib="true"/>
import React, {ChangeEvent, useState, useEffect} from 'react';
import {useStyles} from './style';
import {Grid} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {
    MODULE_TEXT,
    MODULE_IMAGE,
    MODULE_SERVICE,
    MODULE_CARD,
    MODULE_COLLAPSE,
    MODULE_RESERVATION,
    MODULE_FLIGHT_SCHEDULE,
    MODULE_GALLERY_MEDIA,
    MODULE_MAGAZINE_GALLERY,
    MODULE_GALLERY_IMAGE,
    singleModules,
    MODULE_RATE_TABLE,
    MODULE_NETWORK_CARD,
    MODULE_TIMELINE,
    MODULE_IMAGE_TEXT,
    MODULE_PHOTO_LIBRARY,
} from '../../utils/modules';
import {
    Button,
    CustomTabs,
    ImageModule,
    InputsContainer,
    SelectInput,
    TinyMceWysiwyg,
    MediaGalleryForm,
    ModuleServiceForm,
    ModuleCardForm,
    ModuleCollapseForm,
    ModuleReservationBlocForm,
    ModuleFlightScheduleForm,
    ModuleRateTable,
    ModuleCardNetworkForm,
    TimeLineForm,
    ModuleImageText,
} from '..';
import {
    Card,
    Collapse,
    DefaultModuleError,
    Module,
    ModuleConfiguration,
    ModuleMedia,
    Wysywig,
} from '../../models/Page';
import {FILE_MAX_SIZE, LANGUAGE, VALIDATION} from '../../utils/constants';
import {useAddMedia} from '../../hooks';
import {
    checkValidations,
    getJson,
    getStringFromJson,
} from '../../utils/helpers';
import {showSnackBar} from '../../store/snackBar/actions';
import {useDispatch} from 'react-redux';
import {ServiceModel} from '../../models';
import MagazineGalleryForm from 'components/MagazineGalleryForm/MagazineGalleryForm';
import {PhotoLibraryModule} from 'components/PhotoLibraryModule/PhotoLibrarymodule';

interface MainProps {
    module: Module;
    changeModule: (module: Module) => void;
    handleCancelButton: () => void;
    onSubmitForm: () => void;
    loading?: boolean;
    typePageforCollapse: number;
}
export const ModuleForm = (props: MainProps) => {
    const {
        module,
        changeModule,
        onSubmitForm,
        handleCancelButton,
        loading = false,
    } = props;
    const classes = useStyles();
    const {t} = useTranslation();
    const [erorSelect, setErrorSelect] = useState('');
    const [galleryType, setGalleryType] = useState(0);
    const [moduleErrors, setModuleErrors] = useState({...DefaultModuleError});
    const [textFr, setTextFr] = useState(
        module.wysywigs.find((value) => value.language_id === LANGUAGE.FRENCH)
            ?.content,
    );
    const [textEn, setTextEn] = useState(
        module.wysywigs.find((value) => value.language_id === LANGUAGE.ENGLISH)
            ?.content,
    );
    const [textDe, setTextDe] = useState(
        module.wysywigs.find((value) => value.language_id === LANGUAGE.DEUTSCH)
            ?.content,
    );
    const dispatch = useDispatch();

    const handleGalleryTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (e.target !== undefined) {
            setGalleryType(e.target.value);
        }
    };
    const onChangeFlex = (e: ChangeEvent<HTMLSelectElement>) => {
        changeModule({
            ...module,
            flex_grow: Number(e.target.value || 1),
        });
        setModuleErrors((prev) => ({
            ...prev,
            flex_grow: '',
        }));
    };

    const submitModule = () => {
        if (moduleIsValid()) {
            onSubmitForm();
        }
    };

    const onChangeServices = (data: Array<ServiceModel>) => {
        setModuleErrors((prev) => ({
            ...prev,
            services_plus: '',
        }));
        changeModule({
            ...module,
            services_plus: [...data],
        });
    };

    const onChangeCards = (data: Array<Card>) => {
        setModuleErrors((prev) => ({
            ...prev,
            cards: '',
        }));
        changeModule({
            ...module,
            cards: [...data],
        });
    };

    const onChangeCollapse = (data: Array<Collapse>) => {
        setModuleErrors((prev) => ({
            ...prev,
            collapses: '',
        }));
        changeModule({
            ...module,
            collapses: [...data],
        });
    };

    const moduleIsValid = () => {
        let test = true;
        if (!module.flex_grow) {
            setErrorSelect('');
        }
        switch (module.type) {
            case MODULE_TEXT:
                if (!textFr) {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        wysywigsFr: 'validation.textFr.required',
                    }));
                }
                if (!textEn) {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        wysywigsEn: 'validation.textEn.required',
                    }));
                }
                if (!textDe) {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        wysywigsDe: 'validation.textDe.required',
                    }));
                }
                break;
            case MODULE_IMAGE:
                if (
                    !module.image[0] ||
                    (!module.image[0].image && !module.image[0].path)
                ) {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        image: 'validation.image.required',
                    }));
                }
                break;
            case MODULE_GALLERY_IMAGE:
                if (!module.media || module.media.length <= 0) {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        media: 'validation.gallery_image.required',
                    }));
                }
                break;
            case MODULE_PHOTO_LIBRARY:
                if (!module.photos || module.photos.length <= 0) {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        photos: 'validation.gallery_image.required',
                    }));
                }
                break;
            case MODULE_GALLERY_MEDIA:
                if (!module.gallery_media || module.gallery_media.length <= 0) {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        gallery_media: 'validation.gallery_media.required',
                    }));
                }
                break;
            case MODULE_MAGAZINE_GALLERY:
                if (
                    !module.gallery_magazine ||
                    module.gallery_magazine.length <= 0
                ) {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        gallery_magazine:
                            'validation.gallery_magazine.required',
                    }));
                }
                break;
            case MODULE_SERVICE:
                if (!module.services_plus || module.services_plus.length <= 0) {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        services_plus: 'validation.services_plus.required',
                    }));
                }
                break;
            case MODULE_CARD:
                const configLocal3 = getJson(module.configuration);
                if (!module.cards || module.cards.length <= 0) {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        cards: 'validation.cards.required',
                    }));
                }
                if (
                    module.cards?.length <
                        Number(configLocal3.number_per_module) &&
                    module.cards?.length != 0
                ) {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        number_per_module: 'validation.cards.number_per_module',
                    }));
                }
                break;
            case MODULE_COLLAPSE:
                if (!module.collapses || module.collapses.length <= 0) {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        collapses: 'validation.collapses.required',
                    }));
                }
                break;
            case MODULE_RATE_TABLE:
                const config = getJson(module.configuration);
                if (config.departure == '' && config.destination == '') {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        departure: 'validation.rate_table.route',
                    }));
                }
                if (config.departure == '') {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        departure: 'validation.rate_table.departure',
                    }));
                }
                if (config.destination == '') {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        destination: 'validation.rate_table.destination',
                    }));
                }
                break;
            case MODULE_TIMELINE:
                break;
            case MODULE_IMAGE_TEXT:
                const configLocal2 = getJson(module.configuration);
                if (!textFr) {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        wysywigsFr: 'validation.textFr.required',
                    }));
                }
                if (!textEn) {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        wysywigsEn: 'validation.textEn.required',
                    }));
                }
                if (!textDe) {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        wysywigsDe: 'validation.textDe.required',
                    }));
                }
                if (
                    !module.image[0] ||
                    (!module.image[0].image && !module.image[0].path)
                ) {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        image: 'validation.image.required',
                    }));
                }
                if (!configLocal2.bloc_position) {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        bloc_position: t('validation.bloc_position.required'),
                    }));
                }
                if (!configLocal2.image_position) {
                    test = false;
                    setModuleErrors((prev) => ({
                        ...prev,
                        image_position: t('validation.image_position.required'),
                    }));
                }
                break;
        }
        return test;
    };

    const changeModuleText = (field: number) => (text: string) => {
        const wysywigstoEdit: Wysywig[] = module.wysywigs.map((wysywig) =>
            wysywig.language_id === field
                ? {...wysywig, content: text}
                : wysywig,
        );

        switch (field) {
            case LANGUAGE.FRENCH:
                setTextFr(text);
                setModuleErrors((prev) => ({
                    ...prev,
                    wysywigsFr: '',
                }));
                break;
            case LANGUAGE.ENGLISH:
                setTextEn(text);
                setModuleErrors((prev) => ({
                    ...prev,
                    wysywigsEn: '',
                }));
                break;
            case LANGUAGE.DEUTSCH:
                setTextDe(text);
                setModuleErrors((prev) => ({
                    ...prev,
                    wysywigsDe: '',
                }));
                break;
        }

        changeModule({
            ...module,
            wysywigs: [...wysywigstoEdit],
        });
    };

    const onChangeImageModule = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target && e.target.files && e.target.files[0]) {
            const image = e.target.files[0];
            const url = URL.createObjectURL(image);
            changeModule({
                ...module,
                image: [{...module.image[0], path: url, image: image}],
            });

            setModuleErrors((prev) => ({
                ...prev,
                image: '',
            }));

            e.target.value = '';
        }
    };

    const onChangeConfiguration = (
        config: ModuleConfiguration,
        field: string,
    ) => {
        setModuleErrors((prev) => ({
            ...prev,
            configuration: {
                ...moduleErrors.configuration,
                [field]: '',
            },
        }));
        if (module.type == MODULE_RATE_TABLE) {
            setModuleErrors((prev) => ({
                ...prev,
                [field]: '',
            }));
        }
        if (
            module.type == MODULE_TIMELINE ||
            module.type == MODULE_IMAGE_TEXT ||
            module.type == MODULE_PHOTO_LIBRARY
        ) {
            setModuleErrors((prev) => ({
                ...prev,
                [field]: '',
            }));
            setModuleErrors((prev) => ({
                ...prev,
                configuration: {
                    ...moduleErrors.configuration,
                    [field]: '',
                },
            }));
        }
        changeModule({
            ...module,
            configuration: getStringFromJson(config),
        });
    };

    // get media url s3
    const {loader: loaderAddMediaS3, onSubmit: onSubmitAddMediaS3} =
        useAddMedia();

    const onChangeImageGalleryModule = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (e.target && e.target.files && e.target.files[0]) {
            const image = e.target.files[0];
            const customError = checkValidations(
                'imageUrl',
                image.size || '',
                [VALIDATION.MAX],
                FILE_MAX_SIZE,
            );

            if (customError === 'validation.imageUrl.max') {
                dispatch(showSnackBar(t(customError), 'error'));
                e.target.value = '';
            } else {
                onSubmitAddMediaS3(image).then((newMedia) => {
                    if (newMedia != null) {
                        const url = URL.createObjectURL(image);

                        changeModule({
                            ...module,
                            media: [
                                ...module.media,
                                {
                                    path: url,
                                    id: newMedia.id,
                                },
                            ],
                        });

                        setModuleErrors((prev) => ({
                            ...prev,
                            media: '',
                        }));
                    }
                    e.target.value = '';
                });
            }
        }
    };

    const onRemoveImageFromGallery = (id: number) => {
        const index = module.media.findIndex((media) => media.id == id);

        if (index !== -1) {
            const newMedia = [...module.media];
            newMedia.splice(index, 1);
            changeModule({
                ...module,
                media: [...newMedia],
            });
            setModuleErrors((prev) => ({
                ...prev,
                media: '',
            }));
        }
    };

    const onChangePhotosGalleryModule = (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (e.target && e.target.files && e.target.files[0]) {
            const image = e.target.files[0];
            const customError = checkValidations(
                'imageUrl',
                image.size || '',
                [VALIDATION.MAX],
                FILE_MAX_SIZE,
            );

            if (customError === 'validation.imageUrl.max') {
                dispatch(showSnackBar(t(customError), 'error'));
                e.target.value = '';
            } else {
                onSubmitAddMediaS3(image).then((newMedia) => {
                    if (newMedia != null) {
                        const url = URL.createObjectURL(image);

                        changeModule({
                            ...module,
                            photos: [
                                ...module.photos,
                                {
                                    path: url,
                                    id: newMedia.id,
                                },
                            ],
                        });

                        setModuleErrors((prev) => ({
                            ...prev,
                            photos: '',
                        }));
                    }
                    e.target.value = '';
                });
            }
        }
    };

    const onRemovePhotoFromGallery = (id: number) => {
        const index = module.photos.findIndex((media) => media.id == id);

        if (index !== -1) {
            const newMedia = [...module.photos];
            newMedia.splice(index, 1);
            changeModule({
                ...module,
                photos: [...newMedia],
            });
            setModuleErrors((prev) => ({
                ...prev,
                photos: '',
            }));
        }
    };
    const onChangeMediaGalleryModule = (data: Array<ModuleMedia>) => {
        changeModule({
            ...module,
            gallery_media: [...data],
        });
        setModuleErrors((prev) => ({
            ...prev,
            gallery_media: '',
        }));
    };

    const onChangeMagazineGalleryModule = (data: Array<ModuleMedia>) => {
        changeModule({
            ...module,
            gallery_magazine: [...data],
        });
        setModuleErrors((prev) => ({
            ...prev,
            gallery_magazine: '',
        }));
    };

    useEffect(() => {
        if (module.type == MODULE_CARD && !module.configuration) {
            changeModule({
                ...module,
                configuration: getStringFromJson({number_per_module: 3}),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid>
            {!singleModules.includes(module.type) && (
                <InputsContainer title={t('updatePage.module_flex')}>
                    <SelectInput
                        error={erorSelect}
                        label={t('addPromotionSlider.departure_select_title')}
                        value={module.flex_grow}
                        onChange={onChangeFlex}
                        data={[
                            {id: 3, name: t('updatePage.module_flex_1')},
                            {id: 2, name: t('updatePage.module_flex_2')},
                            {id: 1, name: t('updatePage.module_flex_3')},
                        ]}
                    />
                </InputsContainer>
            )}
            {module.type === MODULE_TEXT ? (
                <Grid container className={classes.textModuleContainer}>
                    <InputsContainer title={t('updatePage.module_texte')}>
                        <CustomTabs
                            childrenFR={
                                <div>
                                    <TinyMceWysiwyg
                                        onEditorStateChange={changeModuleText(
                                            LANGUAGE.FRENCH,
                                        )}
                                        content={textFr}
                                        placeholder={t(
                                            'addDestination.sub_title_description',
                                        )}
                                    />
                                </div>
                            }
                            childrenEN={
                                <div>
                                    <TinyMceWysiwyg
                                        onEditorStateChange={changeModuleText(
                                            LANGUAGE.ENGLISH,
                                        )}
                                        content={textEn}
                                        placeholder={t(
                                            'addDestination.sub_title_description',
                                        )}
                                    />
                                </div>
                            }
                            childrenDE={
                                <div>
                                    <TinyMceWysiwyg
                                        onEditorStateChange={changeModuleText(
                                            LANGUAGE.DEUTSCH,
                                        )}
                                        content={textDe}
                                        placeholder={t(
                                            'addDestination.sub_title_description',
                                        )}
                                    />
                                </div>
                            }
                        />
                        {moduleErrors.wysywigsFr && (
                            <p className={classes.errorWysiwyg}>
                                {t(moduleErrors.wysywigsFr)}
                            </p>
                        )}
                        {moduleErrors.wysywigsEn && (
                            <p className={classes.errorWysiwyg}>
                                {t(moduleErrors.wysywigsEn)}
                            </p>
                        )}
                        {moduleErrors.wysywigsDe && (
                            <p className={classes.errorWysiwyg}>
                                {t(moduleErrors.wysywigsDe)}
                            </p>
                        )}
                    </InputsContainer>
                </Grid>
            ) : module.type === MODULE_IMAGE ? (
                <Grid container>
                    <ImageModule
                        value={
                            module.image &&
                            module.image[0] &&
                            module.image[0].path
                                ? module.image[0].path
                                : ''
                        }
                        error={moduleErrors.image}
                        onChange={onChangeImageModule}
                    />
                </Grid>
            ) : module.type === MODULE_SERVICE ? (
                <ModuleServiceForm
                    onChangeServices={onChangeServices}
                    services={module.services_plus}
                    error={moduleErrors.services_plus}
                />
            ) : module.type === MODULE_CARD ? (
                <ModuleCardForm
                    onChangeCards={onChangeCards}
                    cards={module.cards}
                    error={moduleErrors}
                    configuration={
                        module.configuration
                            ? getJson(module.configuration)
                            : {number_per_module: 5}
                    }
                    onChangeConfiguration={onChangeConfiguration}
                />
            ) : module.type === MODULE_COLLAPSE ? (
                <ModuleCollapseForm
                    onChangeCollapse={onChangeCollapse}
                    collapses={module.collapses}
                    error={moduleErrors.collapses}
                    typePageforCollapse={props.typePageforCollapse}
                />
            ) : module.type === MODULE_GALLERY_IMAGE ? (
                <Grid>
                    <ImageModule
                        error={moduleErrors.media}
                        values={module.media}
                        isMultiple
                        title="common.images"
                        onChange={onChangeImageGalleryModule}
                        loading={loaderAddMediaS3}
                        onRemoveImage={onRemoveImageFromGallery}
                    />
                </Grid>
            ) : module.type === MODULE_PHOTO_LIBRARY ? (
                <Grid>
                    <PhotoLibraryModule
                        errorMedia={moduleErrors.photos}
                        values={module.photos}
                        isMultiple
                        title="common.images"
                        onChange={onChangePhotosGalleryModule}
                        loading={loaderAddMediaS3}
                        onRemoveImage={onRemovePhotoFromGallery}
                        configuration={getJson(module.configuration)}
                        onChangeConfiguration={onChangeConfiguration}
                    />
                </Grid>
            ) : module.type === MODULE_GALLERY_MEDIA ? (
                <Grid>
                    <MediaGalleryForm
                        error={moduleErrors.gallery_media}
                        gallery={module.gallery_media}
                        onChange={onChangeMediaGalleryModule}
                    />
                </Grid>
            ) : module.type === MODULE_MAGAZINE_GALLERY ? (
                <Grid>
                    <MagazineGalleryForm
                        error={moduleErrors.gallery_magazine}
                        gallery={module.gallery_magazine}
                        onChange={onChangeMagazineGalleryModule}
                        configuration={getJson(module.configuration)}
                        onChangeConfiguration={onChangeConfiguration}
                    />
                </Grid>
            ) : module.type === MODULE_RESERVATION ? (
                <ModuleReservationBlocForm
                    configuration={getJson(module.configuration)}
                    onChangeConfiguration={onChangeConfiguration}
                    errors={moduleErrors.configuration}
                />
            ) : module.type === MODULE_FLIGHT_SCHEDULE ? (
                <ModuleFlightScheduleForm
                    configuration={getJson(module.configuration)}
                    onChangeConfiguration={onChangeConfiguration}
                    errors={moduleErrors.configuration}
                />
            ) : module.type === MODULE_RATE_TABLE ? (
                <ModuleRateTable
                    configuration={getJson(module.configuration)}
                    onChangeConfiguration={onChangeConfiguration}
                    errors={moduleErrors}
                />
            ) : module.type === MODULE_NETWORK_CARD ? (
                <ModuleCardNetworkForm
                    configuration={getJson(module.configuration)}
                    onChangeConfiguration={onChangeConfiguration}
                    errors={moduleErrors}
                />
            ) : module.type === MODULE_TIMELINE ? (
                <TimeLineForm
                    error={[
                        moduleErrors.gallery_media,
                        moduleErrors.titleFr,
                        moduleErrors.titleEn,
                        moduleErrors.titleDe,
                    ]}
                    gallery={module.gallery_media}
                    onChange={onChangeMediaGalleryModule}
                    configuration={getJson(module.configuration)}
                    onChangeConfiguration={onChangeConfiguration}
                />
            ) : module.type === MODULE_IMAGE_TEXT ? (
                <ModuleImageText
                    changeModuleText={changeModuleText}
                    titles={[textFr, textEn, textDe]}
                    image={module.image}
                    onChangeImageModule={onChangeImageModule}
                    errors={[
                        moduleErrors.position_bloc ?? '',
                        moduleErrors.position_image ?? '',
                        moduleErrors.titleFr ?? '',
                        moduleErrors.titleEn ?? '',
                        moduleErrors.titleDe ?? '',
                        moduleErrors.image ?? '',
                        moduleErrors.wysywigsFr ?? '',
                        moduleErrors.wysywigsEn ?? '',
                        moduleErrors.wysywigsDe ?? '',
                    ]}
                    configuration={getJson(module.configuration)}
                    onChangeConfiguration={onChangeConfiguration}
                />
            ) : (
                <></>
            )}

            <div className={classes.containerSubmitButton}>
                <div className={classes.cancelContainer}>
                    <Button
                        disabled={loading || loaderAddMediaS3}
                        onClick={handleCancelButton}
                        title={t('common.cancel_button')}
                    />
                </div>
                <Button
                    disabled={loading || loaderAddMediaS3}
                    type="submit"
                    onClick={() => submitModule()}
                    title={t('common.save_button')}
                    loader={loading}
                />
            </div>
        </Grid>
    );
};

export default ModuleForm;

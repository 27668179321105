import {api} from 'api';
import React, {FormEvent, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {showSnackBar} from 'store/snackBar/actions';
import {
    PATHS,
    VALIDATION,
    checkValidations,
    getErrorMessage,
    history,
    isValidForm,
} from 'utils';

interface AirportModel {
    code: string;
    cityCode: string;
    airportNameFr: string;
    airportNameEn: string;
    airportNameDe: string;
    airportCityNameFr: string;
    airportCityNameEn: string;
    airportCityNameDe: string;
    longitude: number;
    latitude: number;
    airportCountryId: number;
}

interface AirportError {
    code: string;
    cityCode: string;
    airportNameFr: string;
    airportNameEn: string;
    airportNameDe: string;
    airportCityNameFr: string;
    airportCityNameEn: string;
    airportCityNameDe: string;
    longitude: string;
    latitude: string;
    airportCountryId: string;
}

function useManageAirport(
    initialData: AirportModel,
    isUpdate?: boolean,
    airportId?: number,
) {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [loader, setLoader] = useState<boolean>(false);

    const [data, setData] = useState<AirportModel>(initialData);

    const [error, setError] = useState<AirportError>({
        code: '',
        cityCode: '',
        airportNameFr: '',
        airportNameEn: '',
        airportNameDe: '',
        airportCityNameFr: '',
        airportCityNameEn: '',
        airportCityNameDe: '',
        longitude: '',
        latitude: '',
        airportCountryId: '',
    });

    const isValidCoordinate = (value: number, min: number, max: number) => {
        return value >= min && value <= max;
    };

    const onChange = useCallback(
        (field: string, min?: number, max?: number) => (e) => {
            const value = e.target.value;

            if (min && max && !isValidCoordinate(value, min, max)) {
                dispatch(showSnackBar(t(`airport.${field}_invalid`), 'error'));
            } else {
                setData({...data, [field]: value});
                setError({...error, [field]: ''});
            }
        },
        [error, data],
    );

    const validate = useCallback(() => {
        const _error = {...error};
        _error.code = checkValidations(
            'code',
            data.code,
            [VALIDATION.REQUIRED],
            undefined,
            _error.code,
        );

        _error.cityCode = checkValidations(
            'cityCode',
            data.cityCode,
            [VALIDATION.REQUIRED],
            undefined,
            _error.cityCode,
        );

        _error.longitude = checkValidations(
            'longitude',
            data.longitude,
            [VALIDATION.REQUIRED],
            undefined,
            _error.longitude,
        );

        _error.latitude = checkValidations(
            'latitude',
            data.latitude,
            [VALIDATION.REQUIRED],
            undefined,
            _error.latitude,
        );

        _error.airportNameFr = checkValidations(
            'airportNameFr',
            data.airportNameFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.airportNameFr,
        );
        _error.airportNameEn = checkValidations(
            'airportNameEn',
            data.airportNameEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.airportNameEn,
        );
        _error.airportNameDe = checkValidations(
            'airportNameEn',
            data.airportNameDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.airportNameDe,
        );
        _error.airportCityNameFr = checkValidations(
            'airportCityNameFr',
            data.airportCityNameFr,
            [VALIDATION.REQUIRED],
            undefined,
            _error.airportCityNameFr,
        );
        _error.airportCityNameEn = checkValidations(
            'airportCityNameEn',
            data.airportCityNameEn,
            [VALIDATION.REQUIRED],
            undefined,
            _error.airportCityNameEn,
        );
        _error.airportCityNameDe = checkValidations(
            'airportCityNameDe',
            data.airportCityNameDe,
            [VALIDATION.REQUIRED],
            undefined,
            _error.airportCityNameDe,
        );

        _error.airportCountryId = checkValidations(
            'airportCountryId',
            data.airportCountryId,
            [VALIDATION.REQUIRED],
            undefined,
            _error.airportCountryId,
        );

        setError(_error);
        return isValidForm(_error);
    }, [error, data]);

    const onSubmit = useCallback(async () => {
        if (validate()) {
            setLoader(true);
            try {
                isUpdate && airportId
                    ? await api.airports.update(airportId, {
                          code: data.code,
                          city_code: data.cityCode,
                          longitude: data.longitude,
                          latitude: data.latitude,
                          airport_country_id: data.airportCountryId,
                          airport_name_fr: data.airportNameFr,
                          airport_name_en: data.airportNameEn,
                          airport_name_de: data.airportNameDe,
                          airport_city_name_fr: data.airportCityNameFr,
                          airport_city_name_en: data.airportCityNameEn,
                          airport_city_name_de: data.airportCityNameDe,
                      })
                    : await api.airports.create({
                          code: data.code,
                          city_code: data.cityCode,
                          longitude: data.longitude,
                          latitude: data.latitude,
                          airport_country_id: data.airportCountryId,
                          airport_name_fr: data.airportNameFr,
                          airport_name_en: data.airportNameEn,
                          airport_name_de: data.airportNameDe,
                          airport_city_name_fr: data.airportCityNameFr,
                          airport_city_name_en: data.airportCityNameEn,
                          airport_city_name_de: data.airportCityNameDe,
                      });

                dispatch(
                    showSnackBar(
                        isUpdate && airportId
                            ? t('airport.airport_updated')
                            : t('airport.airport_created'),
                        'success',
                    ),
                );
                setLoader(false);
                history.push(PATHS.AIRPORT);
            } catch (er: any) {
                setLoader(false);
                if (er.response?.status === 422) {
                    dispatch(
                        showSnackBar(t('airport.airport_existed'), 'error'),
                    );
                } else {
                    dispatch(showSnackBar(getErrorMessage(er), 'error'));
                }
            }
        } else {
            dispatch(showSnackBar(t('common.validation_error'), 'error'));
        }
    }, [validate, setLoader, dispatch, t, data]);

    const resetData = useCallback(
        () => {
            setData({
                code: '',
                cityCode: '',
                airportNameFr: '',
                airportNameEn: '',
                airportNameDe: '',
                airportCityNameFr: '',
                airportCityNameEn: '',
                airportCityNameDe: '',
                longitude: 0,
                latitude: 0,
                airportCountryId: 0,
            });
            setError({
                code: '',
                cityCode: '',
                airportNameFr: '',
                airportNameEn: '',
                airportNameDe: '',
                airportCityNameFr: '',
                airportCityNameEn: '',
                airportCityNameDe: '',
                longitude: '',
                latitude: '',
                airportCountryId: '',
            });
        }, // eslint-disable-next-line
        [],
    );
    return {data, error, loader, onChange, onSubmit, resetData, setData};
}

export default useManageAirport;

/// <reference no-default-lib="true"/>
import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {Grid, IconButton, Tooltip} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'store/rootReducer';
import {
    deletePhotoLibraryGroup,
    fetchPhotoLibraryGroups,
} from 'api/photoLibraryGroup';
import {
    deletePhotoLibraryGroupAction,
    fetchPhotoLibraryGroupsAction,
} from 'store/PhotoLibrary/actions';
import FormContainer from 'components/FormContainer/FormContainer';
import Table from 'components/Table/Table';
import {PhotoLibraryGroup} from 'models/PhotoLibraryGroup';
import Media from 'components/Media/Media';
import {Delete, Edit} from '@material-ui/icons';
import {useStyles} from './style';
import AddButton from 'components/AddButton/AddButton';
import {useTranslation} from 'react-i18next';
import CustomDialog from 'components/Dialog/CustomDialog';
import {MediaForm} from 'components/MediaForm/MediaForm';
import PhotoLibraryForm from 'components/PhotoLibraryForm/PhotoLibraryForm';
import Pagination from 'components/Pagination/Pagination';
import Button from 'components/Button/Button';
import {ACCESS, SECTIONS, getAccessSection} from 'utils';

export const PaginatedPhotoLibraryGroups = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [orderDir, setOrderDir] = useState<'' | 'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<string>('priority');
    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [id, setId] = useState<string>();
    const [listLoader, setListLoader] = useState<boolean>(false);
    const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [addDialog, setAddDialog] = useState(false);
    const [itemToUpdate, setItemToUpdate] = useState<PhotoLibraryGroup>();
    const [indexUpdate, setIndexUpdate] = useState(0);

    const [updateDialog, setUpdateDialog] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(0);

    const paginatedPhotoGroups = useSelector(
        (state: RootState) => state.photoLibraryGroup.photoLibraryGroups,
    );

    const lastPage = useSelector(
        (state: RootState) => state.photoLibraryGroup.last_page,
    );

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };
    const onSearchChange = useCallback(
        (e: ChangeEvent<HTMLInputElement> | string) => {
            setPage(1);
            if (typeof e === 'string') {
                setSearch(e);
            } else {
                setSearch(e.target.value);
            }
        },
        [setPage],
    );
    const onPerPageChange = useCallback(
        (
            event: React.ChangeEvent<{
                name?: string | undefined;
                value: string;
            }>,
        ) => {
            setPerPage(parseInt(event.target.value));
            setPage(1);
        },
        [setPerPage, setPage],
    );
    const onOrderChange = useCallback(
        (field: string) => () => {
            const order_dir =
                orderBy == field
                    ? orderDir === 'asc'
                        ? 'desc'
                        : orderDir === 'desc'
                        ? ''
                        : 'asc'
                    : 'asc';
            setOrderDir(order_dir);
            setOrderBy(order_dir == '' ? '' : field);
        },
        [orderBy, orderDir],
    );
    const handleRefresh = () => {
        setRefresh(true);
    };
    const openDeleteDialogFunction = (id: number) => {
        setDeleteDialog(true);
        setItemToDelete(id);
    };

    const openUpdateDialogFunction = (index: number) => {
        setIndexUpdate(index);
        setUpdateDialog(true);
    };
    const openAddDialogFunction = () => {
        setAddDialog(true);
    };

    const deleteGroup = async () => {
        setDeleteLoader(true);
        await dispatch(deletePhotoLibraryGroupAction(itemToDelete));
        setDeleteLoader(false);
        handleRefresh();
        setDeleteDialog(false);
    };

    const PaginatedGroup = async () => {
        setListLoader(true);
        await dispatch(
            fetchPhotoLibraryGroupsAction({
                page: page,
                perPage: perPage,
                keyword: search,
                orderBy: orderBy,
                direction: orderDir,
            }),
        );
        setRefresh(false);
        setListLoader(false);
    };
    useEffect(() => {
        PaginatedGroup();
    }, [perPage, page, dispatch, orderBy, orderDir, refresh]);

    return (
        <Grid>
            <FormContainer title={t('photoLibraryGroup.groups')}>
                <Table
                    loader={listLoader}
                    headerData={[
                        {
                            key: 'title',
                            title: t('addDestination.title_item'),
                        },
                        {
                            key: 'url',
                            title: t('addDestination.urlGallery'),
                        },
                        {
                            key: 'priority',
                            title: t('addDestination.priorityGallery'),
                        },

                        {key: 'action', title: ''},
                    ]}
                    bodyData={
                        paginatedPhotoGroups &&
                        paginatedPhotoGroups?.map(
                            (group: PhotoLibraryGroup, index: number) => {
                                return {
                                    title: <p>{group?.title}</p>,
                                    url: <p>{group.url}</p>,
                                    priority: <p>{group.priority}</p>,
                                    action: (
                                        <div style={{display: 'flex'}}>
                                            <div>
                                                {(getAccessSection(
                                                    SECTIONS
                                                        .CORPORATE_NEWS_SECTION
                                                        .id,
                                                ) == ACCESS.ALL ||
                                                    getAccessSection(
                                                        SECTIONS
                                                            .CORPORATE_NEWS_SECTION
                                                            .id,
                                                    ) ==
                                                        ACCESS.ACCESS_UPDATE) && (
                                                    <Tooltip
                                                        title={t(
                                                            'common.edit',
                                                        )}>
                                                        <IconButton
                                                            color="primary"
                                                            component="span"
                                                            onClick={() =>
                                                                openUpdateDialogFunction(
                                                                    group.id,
                                                                )
                                                            }>
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </div>
                                            <div>
                                                {(getAccessSection(
                                                    SECTIONS
                                                        .CORPORATE_NEWS_SECTION
                                                        .id,
                                                ) == ACCESS.ALL ||
                                                    getAccessSection(
                                                        SECTIONS
                                                            .CORPORATE_NEWS_SECTION
                                                            .id,
                                                    ) ==
                                                        ACCESS.ACCESS_DELETE) && (
                                                    <Tooltip
                                                        title={t(
                                                            'common.delete',
                                                        )}>
                                                        <IconButton
                                                            color="primary"
                                                            component="span"
                                                            onClick={() =>
                                                                openDeleteDialogFunction(
                                                                    group.id,
                                                                )
                                                            }>
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </div>
                                    ),
                                };
                            },
                        )
                    }
                />
                <Grid justifyContent="center" container>
                    <Pagination
                        lastPage={lastPage}
                        page={page}
                        onPerPageChange={onPerPageChange}
                        perPage={perPage}
                        onPageChange={(_event, value) =>
                            handlePageChange(value)
                        }
                    />
                </Grid>
                {(getAccessSection(SECTIONS.CORPORATE_NEWS_SECTION.id) ==
                    ACCESS.ALL ||
                    getAccessSection(SECTIONS.CORPORATE_NEWS_SECTION.id) ==
                        ACCESS.ACCESS_UPDATE) && (
                    <Grid
                        justifyContent="center"
                        container
                        className={classes.addButtonContainer}>
                        <AddButton
                            onAddClick={openAddDialogFunction}
                            title={t('common.add')}
                        />
                    </Grid>
                )}
            </FormContainer>
            <CustomDialog
                open={updateDialog}
                handleClose={() => setUpdateDialog(false)}
                title={t('photoLibraryGroup.update_group')}
                withScroll={true}>
                <PhotoLibraryForm
                    handleClose={() => setUpdateDialog(false)}
                    indexUpdate={indexUpdate}
                    isUpdate={true}
                    handleRefresh={handleRefresh}
                />
            </CustomDialog>
            <CustomDialog
                open={addDialog}
                handleClose={() => setAddDialog(false)}
                title={t('photoLibraryGroup.add_group')}>
                <PhotoLibraryForm
                    handleClose={() => setAddDialog(false)}
                    handleRefresh={handleRefresh}
                />
            </CustomDialog>
            <CustomDialog
                open={deleteDialog}
                handleClose={() => setDeleteDialog(false)}
                title={t('photoLibraryGroup.delete_group')}>
                <div>
                    <p>{t('photoLibraryGroup.supp_question')}</p>
                    <div className={classes.deleteButtons}>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={false}
                                title={t('common.cancel_button')}
                                onClick={() => setDeleteDialog(false)}
                            />
                        </div>
                        <div className={classes.containerButton}>
                            <Button
                                disabled={deleteLoader}
                                onClick={() => deleteGroup()}
                                loader={deleteLoader}
                                title={t('common.confirm_button')}
                            />
                        </div>
                    </div>
                </div>
            </CustomDialog>
        </Grid>
    );
};

export default PaginatedPhotoLibraryGroups;

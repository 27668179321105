/// <reference no-default-lib="true"/>
import React, {useCallback, useEffect, useState} from 'react';
import {Grid, TextField} from '@material-ui/core';
import {useStyles} from '../../screens/AddDestination/style';
import TextInput from 'components/TextInput/TextInput';
import {useTranslation} from 'react-i18next';
import {
    AddPhotoLibraryGroupModel,
    PhotoLibraryGroupDetails,
    initialErrorPhotoLibraryGroupState,
    initialPhotoLibraryGroupState,
} from 'models/PhotoLibraryGroup';
import Media from 'components/Media/Media';
import FileInput from 'components/FileInput/FileInput';
import {FILE_FORMATS, FILE_IMAGE_VIDEO_FORMATS} from 'utils';
import Button from 'components/Button/Button';
import {useDispatch, useSelector} from 'react-redux';
import {
    addPhotoLibraryGroupAction,
    getPhotoLibraryGroupAction,
    updatePhotoLibraryGroupAction,
} from 'store/PhotoLibrary/actions';
import {getPhotoLibraryGroup} from 'api/photoLibraryGroup';
import InputsContainer from 'components/InputsContainer/InputsContainer';
import placeHolder from '../../assets/img/img_place_holder.jpg';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

interface MainProps {
    handleClose?: () => void;
    indexUpdate?: number;
    isUpdate?: boolean;
    handleRefresh?: () => void;
}
export const PhotoLibraryForm = (props: MainProps) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [isImageChanged, setIsImageChanged] = useState(false);
    const [photoLibraryGroupData, setPhotoLibraryGroupData] =
        useState<AddPhotoLibraryGroupModel>(initialPhotoLibraryGroupState);

    const [photoLibraryGroupError, setPhotoLibraryGroupError] =
        useState<AddPhotoLibraryGroupModel>(initialErrorPhotoLibraryGroupState);

    const PhotoGroup = useSelector(
        (state: RootState) => state.photoLibraryGroup.photoLibraryGroup,
    );

    const onChange = useCallback(
        (field: string) => (e) => {
            if (e.target != undefined) {
                if (field === 'preview_image') {
                    if (!e.target.files) return;
                    setIsImageChanged(true);
                    const newData = photoLibraryGroupData;
                    newData.preview_image = e.target.files[0];
                    newData.imageUrl = URL.createObjectURL(e.target.files[0]);

                    const filename = e.target.files[0].name;
                    newData.ext = filename.substr(
                        filename.lastIndexOf('.') + 1,
                        filename.length,
                    );
                    setPhotoLibraryGroupData({...newData});

                    e.target.value = '';
                } else {
                    setPhotoLibraryGroupData({
                        ...photoLibraryGroupData,
                        [field]: e.target.value,
                    });
                }
                setPhotoLibraryGroupError({
                    ...photoLibraryGroupError,
                    [field]: '',
                });
            }
        },
        [photoLibraryGroupData, photoLibraryGroupError],
    );

    const photoLibraryGroupIsValid = useCallback(() => {
        let test = true;
        const localState = initialErrorPhotoLibraryGroupState;

        if (!photoLibraryGroupData.titleFr) {
            test = false;
            setPhotoLibraryGroupError((prev) => ({
                ...prev,
                titleFr: 'photoLibraryGroup.title_fr_required',
            }));
        }
        if (!photoLibraryGroupData.titleEn) {
            test = false;
            setPhotoLibraryGroupError((prev) => ({
                ...prev,
                titleEn: 'photoLibraryGroup.title_en_required',
            }));
        }
        if (!photoLibraryGroupData.titleDe) {
            test = false;
            setPhotoLibraryGroupError((prev) => ({
                ...prev,
                titleDe: 'photoLibraryGroup.title_de_required',
            }));
        }
        if (!photoLibraryGroupData.url) {
            test = false;
            setPhotoLibraryGroupError((prev) => ({
                ...prev,
                url: 'photoLibraryGroup.url_required',
            }));
        }
        if (!photoLibraryGroupData.priority) {
            test = false;
            setPhotoLibraryGroupError((prev) => ({
                ...prev,
                priority: 'photoLibraryGroup.priority_required',
            }));
        }
        if (!photoLibraryGroupData.preview_image) {
            test = false;
            setPhotoLibraryGroupError((prev) => ({
                ...prev,
                preview_image: 'photoLibraryGroup.preview_image_required',
            }));
        }
        return test;
    }, [dispatch, photoLibraryGroupData]);

    const handleUpdateGroup = async () => {
        if (photoLibraryGroupIsValid()) {
            setLoader(true);
            await dispatch(
                updatePhotoLibraryGroupAction(
                    props.indexUpdate,
                    photoLibraryGroupData,
                    isImageChanged,
                ),
            );

            setLoader(false);
            props.handleRefresh();
            props.handleClose();
        }
    };

    const handleAddGroup = async () => {
        if (photoLibraryGroupIsValid()) {
            setLoader(true);
            await dispatch(addPhotoLibraryGroupAction(photoLibraryGroupData));

            setLoader(false);
            props.handleRefresh();
            props.handleClose();
        }
    };

    useEffect(() => {
        if (props.isUpdate) {
            dispatch(getPhotoLibraryGroupAction(props.indexUpdate));
        }
    }, [props.indexUpdate]);

    useEffect(() => {
        if (PhotoGroup && props.isUpdate) {
            setPhotoLibraryGroupData({
                ...photoLibraryGroupData,
                url: PhotoGroup?.url,
                preview_image: PhotoGroup?.preview_image,
                priority: PhotoGroup?.priority,
                titleFr: PhotoGroup?.titleFr,
                titleEn: PhotoGroup?.titleEn,
                titleDe: PhotoGroup?.titleDe,
                imageUrl: PhotoGroup?.preview_image,
            });
        }
    }, [PhotoGroup]);

    useEffect(() => {
        setPhotoLibraryGroupData(initialPhotoLibraryGroupState);
    }, []);

    return (
        <>
            <Grid>
                <div className={classes.input}>
                    <TextInput
                        value={photoLibraryGroupData.titleFr}
                        label={t('addDestination.add_image_gallery_title_fr')}
                        onChange={onChange('titleFr')}
                        error={t(photoLibraryGroupError.titleFr)}
                        placeholder={t(
                            'addDestination.add_image_gallery_title_fr',
                        )}
                    />
                </div>

                <div className={classes.input}>
                    <TextInput
                        value={photoLibraryGroupData.titleEn}
                        label={t('addDestination.add_image_gallery_title_en')}
                        onChange={onChange('titleEn')}
                        error={t(photoLibraryGroupError.titleEn)}
                        placeholder={t(
                            'addDestination.add_image_gallery_title_en',
                        )}
                    />
                </div>

                <div className={classes.input}>
                    <TextInput
                        value={photoLibraryGroupData.titleDe}
                        label={t('addDestination.add_image_gallery_title_de')}
                        onChange={onChange('titleDe')}
                        error={t(photoLibraryGroupError.titleDe)}
                        placeholder={t(
                            'addDestination.add_image_gallery_title_de',
                        )}
                    />
                </div>

                <div className={classes.input}>
                    <TextInput
                        value={photoLibraryGroupData.url}
                        label={t('addDestination.add_image_gallery_url')}
                        onChange={onChange('url')}
                        error={t(photoLibraryGroupError.url)}
                        placeholder={t('addDestination.add_image_gallery_url')}
                    />
                </div>

                <div style={{marginBottom: 15}}>
                    <InputsContainer title={t('information.priority')}>
                        <div
                            className={classes.inlineContainerBotton}
                            style={{display: 'flex'}}>
                            <p className={classes.imgMsg}>
                                {t('information.block_priority')}
                            </p>
                            <TextField
                                type="number"
                                InputProps={{
                                    inputProps: {
                                        min: 1,
                                        max: 60,
                                    },
                                }}
                                value={photoLibraryGroupData.priority}
                                onChange={onChange('priority')}
                                className={classes.containerInputPrice}
                                style={{marginLeft: 20}}
                            />
                        </div>
                    </InputsContainer>
                    {photoLibraryGroupError.priority !== '' && (
                        <p className={classes.error}>
                            {t(photoLibraryGroupError.priority)}
                        </p>
                    )}
                </div>

                <InputsContainer title={t('photoLibraryGroup.preview_image')}>
                    <div className={classes.containerTitle}>
                        <p className={classes.textOfTitle}>
                            {t('photoLibraryGroup.preview_image')}
                        </p>

                        <label className={classes.containerUploadIcon}>
                            <CloudUploadIcon className={classes.uploadIcon} />

                            <input
                                type="file"
                                className={classes.inputFile}
                                onChange={onChange('preview_image')}
                                accept={FILE_FORMATS.map((e) => e.format).join(
                                    ',',
                                )}
                            />
                            <p className={classes.uploadImgMsg}>
                                {t('addDestination.upload_image_msg')}
                            </p>
                        </label>
                    </div>
                    <p className={classes.uploadImgMsgInfo}>
                        {t('addDestination.preview_image_info')}
                    </p>

                    <div className={classes.containerPreviewImage}>
                        <img
                            src={
                                photoLibraryGroupData.imageUrl
                                    ? photoLibraryGroupData.imageUrl
                                    : placeHolder
                            }
                            className={classes.previewImage}
                        />
                    </div>
                </InputsContainer>
                {photoLibraryGroupError.preview_image !== '' && (
                    <p className={classes.error}>
                        {t(photoLibraryGroupError.preview_image)}
                    </p>
                )}
                <div style={{marginTop: 12, marginBottom: 12}}>
                    <div className={classes.send}>
                        <div className={classes.cancelContainer}>
                            <Button
                                onClick={() => props.handleClose()}
                                title={t('common.cancel_button')}
                            />
                        </div>
                        <Button
                            onClick={
                                props.isUpdate
                                    ? handleUpdateGroup
                                    : handleAddGroup
                            }
                            title={t('common.save_button')}
                            disabled={loader}
                            loader={loader}
                        />
                    </div>
                </div>
            </Grid>
        </>
    );
};

export default PhotoLibraryForm;
